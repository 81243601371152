"use client";

import { useState } from "react";
import FilterTabList from "../FilterTabList/FilterTabList";
import FilterTabProductCard from "../FilterTabProductCard/FilterTabProductCard";

function FilterTab({ attributes }) {
  const { filteredProducts, tabs } = attributes;
  const [activeProducts, setActiveProducts] = useState(filteredProducts?.[tabs?.[0]?.value] || []);

  return (
    <div className="jlt_tab__wrapper">
      <FilterTabList
        tabs={tabs}
        filteredProducts={filteredProducts}
        setActiveProducts={setActiveProducts}
      />
      <div className="jlt-grid jlt-grid-cols-3 jlt-gap-8 jlt-mt-12">
        {activeProducts?.map((product, i) => (
          <FilterTabProductCard key={i} product={product} />
        ))}
      </div>
    </div>
  );
}

export default FilterTab;
