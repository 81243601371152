"use client";

// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import { Fragment, useEffect, useRef, useState } from "react";
import { getBorderCSS, getBorderRadiusCSS } from "@litonarefin/utils/getBorderCSS";
import { getIcon } from "@litonarefin/utils/icons/index";
import Link from "next/link";
// import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";

const Pricing = ({ attributes, children }) => {
  const {
    className,
    headerBg,
    bgOverlayColor,
    overlayOpacity,
    title,
    subtitle,
    subtitleColor,
    price,
    priceColor,
    priceDuration,
    features,
    featureButton,
    featureTextColor,
    titleColor,
    featureBtnColor,
    featureBtnURL,
    borders,
    borderRadius,
    isActive,
    activeText,
    activeStyle = "default",
    activeColor,
    activeBg,
    activeWidth,
    activeRadius,
    activePosition,
    bgImageURL,
    footerBg,
    buttonPostion = "bottom",
    isDollor = true,
    selectOptions,
  } = attributes;

  const [selectedPlan, setSelectedPlan] = useState(null);

  const selectRef = useRef();

  useEffect(() => {
    setSelectedPlan(selectOptions?.multiple_license?.[0]);
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      const freemiusBtn =
        selectRef.current.parentNode.parentNode.parentNode.parentNode.querySelector(
          ".jlt-freemius-btn"
        );

      if (freemiusBtn) {
        freemiusBtn.setAttribute("plan_id", selectedPlan?.planId);
        freemiusBtn.setAttribute("license", selectedPlan?.value);
      }
    }
  }, [selectedPlan]);

  return (
    <Fragment>
      <div
        {...(attributes?.anchor && { id: attributes?.anchor })}
        // {...allAOS}
        className={`jlt-pricing-wrapper jlt-overflow-hidden jlt-flex jlt-flex-col jlt-justify-between jlt-bg-white jlt-h-full jlt-relative${
          className ? ` ${className}` : ""
        }`}
      >
        {bgOverlayColor ? (
          <div className="jlt__overlay jlt-absolute jlt-top-0 jlt-left-0 jlt-w-full jlt-h-full jlt-z-[2]" />
        ) : null}

        {isActive && activeStyle === "default" && (
          <div className="jlt-feature-label jlt-absolute jlt-bottom-full jlt-text-center jlt-py-1 jlt-px-8 jlt-box-border">
            <span className="jlt-py-1 jlt-font-bold jlt-text-xs jlt-leading-4">{activeText}</span>
          </div>
        )}

        {activeStyle === "right_corner" ? (
          <div
            style={{ background: activeBg }}
            className="jlt-absolute jlt-z-40 jlt-rotate-45 -jlt-right-9 jlt-top-[26px] jlt-w-[146px] jlt-text-center jlt-text-sm jlt-p-[2px]"
          >
            <span style={{ color: activeColor }}>{activeText}</span>
          </div>
        ) : null}
        <div className="jlt-pricing-content jlt-w-full jlt-z-[2]">
          <div className="jlt-pricing-head jlt-py-8 jlt-px-6">
            <h3 className="jlt-font-bold jlt-text-2xl jlt_pricing__title">{title}</h3>
            <p className="jlt-mt-2 jlt-text-sm jlt-text-[#40444e] jlt_pricing__subtitle">
              {subtitle}
            </p>
            <div className="jlt-flex jlt-items-center jlt-mt-4 jlt_pricing__price">
              {isDollor ? (
                <div className="jlt_pricing__price-symbol">
                  <sup className="-jlt-mt-2 jlt-text-xs jlt-leading-[18px] jlt-text-[#16213e] jlt-block">
                    $
                  </sup>
                </div>
              ) : null}

              <h3
                className="jlt-font-bold jlt-text-[32px] jlt-leading-[40px] jlt-text-[#16213e] jlt-mr-2"
                dangerouslySetInnerHTML={{ __html: selectedPlan?.price || price || "" }}
              />
              <span
                className="jlt-text-xs jlt-leading-[18px] jlt-text-[#40444e]"
                dangerouslySetInnerHTML={{
                  __html: priceDuration || "",
                }}
              />
            </div>
          </div>

          {buttonPostion === "bottom" ? <hr className="jlt-mx-6" /> : null}

          {buttonPostion === "top" ? <div className="jlt-px-6 -jlt-mt-4">{children}</div> : null}

          <div className="jlt-pricing-features jlt-py-8 jlt-px-6">
            <ul className="jlt-flex jlt-flex-col jlt-gap-y-4">
              {selectOptions?.multiple_license?.length > 0 ? (
                <li className="jlt-list-none jlt-flex jlt-items-center jlt_feature">
                  <select
                    ref={selectRef}
                    onChange={(e) => {
                      const selectedItem = selectOptions?.multiple_license?.find(
                        (item) => item?.value == e.target.value
                      );
                      setSelectedPlan(selectedItem);
                    }}
                    className="jlt-bg-white jlt-border-2 jlt-w-full jlt-py-[2px] jlt-px-2 jlt-border-[--primary] jlt-text-[--primary] focus:jlt-"
                  >
                    {selectOptions?.multiple_license?.map((opt, i) => (
                      <option key={i} value={opt.value}>
                        {opt.label}
                      </option>
                    ))}
                  </select>
                </li>
              ) : null}

              {features?.map((feature, index) => (
                <li key={index} className="jlt-list-none jlt-flex jlt-items-center jlt_feature">
                  <span>{feature.active ? getIcon("check") : getIcon("close")}</span>
                  <span className="jlt-ml-2 jlt-text-sm jlt_feature__text">{feature.label}</span>
                </li>
              ))}
            </ul>

            <Link
              style={{ color: featureBtnColor }}
              href={featureBtnURL?.url ? `${featureBtnURL?.url}` : "#"}
              className="jlt-mt-4 jlt-font-bold jlt-text-xs jlt-leading-4 jlt-py-2 jlt-px-5 jlt-rounded-sm jlt-bg-none jlt-inline-flex jlt-items-center jlt-no-underline"
            >
              <span className="jlt-mr-2">{featureButton}</span>
              <svg
                className="jlt-mt-[2px]"
                width="13"
                height="8"
                viewBox="0 0 13 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.3536 4.35355C12.5488 4.15829 12.5488 3.84171 12.3536 3.64645L9.17157 0.464466C8.97631 0.269204 8.65973 0.269204 8.46447 0.464466C8.2692 0.659728 8.2692 0.976311 8.46447 1.17157L11.2929 4L8.46447 6.82843C8.2692 7.02369 8.2692 7.34027 8.46447 7.53553C8.65973 7.7308 8.97631 7.7308 9.17157 7.53553L12.3536 4.35355ZM0 4.5H12V3.5H0V4.5Z"
                  fill={` ${featureBtnColor ? featureBtnColor : "#256EFF"}`}
                />
              </svg>
            </Link>
          </div>
        </div>
        {buttonPostion === "bottom" ? (
          <div className="jlt-pricing-footer jlt-py-8 jlt-px-6 jlt-z-[2]">{children}</div>
        ) : null}
      </div>

      <style jsx>{`
        .jlt-pricing-wrapper {
          ${borders ? getBorderCSS(borders) : ""}
          ${borderRadius ? getBorderRadiusCSS(borderRadius) : ""}
                        ${bgImageURL
            ? `background: url(${bgImageURL}); background-size: cover; background-position: center; background-repeat: no-repeat;`
            : ""}
                    
                        > .jlt__overlay {
            ${bgOverlayColor ? `background-color: ${bgOverlayColor};` : ""}
            ${overlayOpacity ? `opacity: ${overlayOpacity};` : ""}
          }

          .jlt-pricing-content {
            > .jlt-pricing-head {
              ${headerBg ? `background: ${headerBg};` : ""}
              .jlt_pricing__title {
                ${titleColor ? `color: ${titleColor};` : ""}
              }
              .jlt_pricing__subtitle {
                ${subtitleColor ? `color: ${subtitleColor};` : ""}
              }
              .jlt_pricing__price {
                .jlt_pricing__price-symbol {
                  sup {
                    ${priceColor ? `color: ${priceColor};` : ""}
                  }
                }
                h3,
                span {
                  ${priceColor ? `color: ${priceColor};` : ""}
                }
              }
            }
            .jlt-pricing-features {
              .jlt_feature {
                .jlt_feature__text {
                  ${featureTextColor ? `color: ${featureTextColor};` : ""}
                }
              }
            }
          }
          .jlt-pricing-footer {
            ${footerBg ? `background: ${footerBg};` : ""}
          }
          .jlt-feature-label {
            ${activeColor ? `color: ${activeColor};` : "color: #fff"}
            ${activeBg ? `background: ${activeBg};` : ""}
                        ${activeRadius
              ? `border-top-left-radius: ${activeRadius}px; border-top-right-radius: ${activeRadius}px;`
              : ""}
                            ${activeWidth
              ? activeWidth === "100%"
                ? `width: 100%;`
                : "width: fit-content;"
              : ""}
                            ${activePosition === "left" || activePosition === "center"
              ? `left: 0;`
              : ""}
                            ${activePosition === "right" || activePosition === "center"
              ? `right: 0;`
              : ""}
                            ${activePosition === "center" ? "margin: auto;" : ""}
          }
        }
      `}</style>
    </Fragment>
  );
};

export default Pricing;
