"use client";

import { useState } from "react";
import { sanitize } from "../../utils/miscellaneous";
// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";

const Faq = ({ attributes }) => {
  const { faqs } = attributes;
  // const allAOS = getAOSAnimation(attributes);

  const [activeFaq, setActiveFaq] = useState(null);

  return (
    <div
      {...(attributes?.anchor && { id: attributes?.anchor })}
      className="jlt-flex jlt-gap-y-8 jlt-flex-col"
    >
      {faqs?.map((faq, index) => (
        <div
          key={index}
          className={`jlt-bg-white jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-lg${
            index === activeFaq ? " jlt__active" : ""
          }`}
        >
          <div
            className="jlt-flex jlt-items-center jlt-cursor-pointer jlt-py-6 jlt-px-8"
            onClick={() => setActiveFaq(index === activeFaq ? null : index)}
          >
            <svg
              className={`jlt-transition-all jlt-duration-200 ${
                index === activeFaq ? "jlt-transform jlt-rotate-180" : ""
              }`}
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M12 0.399902L6 7.5999L0 0.399902H12Z" fill="#72798A" />
            </svg>
            <h3
              className="jlt-ml-5 jlt-text-lg jlt-text-[#1D2939]"
              dangerouslySetInnerHTML={{
                __html: sanitize(faq?.title || ""),
              }}
            />
          </div>

          <div
            className={`jlt-ml-9 jlt-max-h-0 jlt-overflow-hidden jlt-transition-all jlt-duration-300 jlt-px-8${
              index === activeFaq ? " jlt-max-h-96 jlt-pb-8 -jlt-mt-2" : ""
            }`}
          >
            <p
              className="jlt-text-lg jlt-leading-7"
              dangerouslySetInnerHTML={{
                __html: faq?.content || "",
              }}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
