"use client";

import { useEffect, useState } from "react";
import Form from "./Form";
import { isEmpty } from "lodash";
import Loader from "@litonarefin/components/Loader";

const FluentFormClient = ({ attributes }) => {
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const getFormFields = async (form_id) => {
      const res = await fetch(`/api/fluent-form?form_id=${form_id}`, {
        next: { revalidate: 5 },
      });

      const data = await res.json();
      setFormData(data);
      setIsLoading(false);
    };

    if (attributes?.formId) {
      getFormFields(attributes?.formId);
    }
  }, []);

  return (
    <div>
      {!isEmpty(formData) && !isLoading ? (
        <Form formId={attributes?.formId} formData={formData?.data?.form_fields} />
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </div>
  );
};

export default FluentFormClient;
