"use client";

import { getIcon } from "@litonarefin/utils/icons";
import Link from "next/link";
import { Fragment, useState } from "react";

const GlossaryList = ({ posts }) => {
  const [searchText, setSearchText] = useState("");

  return (
    <>
      <div className="jlt-relative jlt-mt-4">
        <input
          type="text"
          placeholder="Search here..."
          className="jlt-py-[14px] jlt-pl-6 jlt-pr-[54px] jlt-border jlt-rounded-full focus:jlt-outline-none jlt-text-sm jlt-text-[#475467] jlt-w-full"
          onChange={(e) => setSearchText(e.target.value)}
        />
        <span className="jlt-absolute jlt-right-6 jlt-top-4 jlt-cursor-pointer">
          {getIcon("search")}
        </span>
      </div>

      <div className="jlt-flex jlt-flex-col jlt-gap-8 jlt-mt-12">
        {Object.keys(posts)
          .sort()
          .filter((key) => key !== "Others")
          ?.filter((key) => {
            const isPosts = posts[key]?.map((post) =>
              post.title?.rendered?.toLowerCase()?.includes(searchText.toLowerCase())
            );

            return isPosts.some(Boolean);
          })
          ?.map((key, i) => (
            <Fragment key={i}>
              <div className="jlt-flex jlt-gap-12">
                <div className="jlt-w-16 jlt-h-16 jlt-bg-gray-200 jlt-flex jlt-items-center jlt-justify-center jlt-text-3xl jlt-rounded-full">
                  {key}
                </div>
                <ul className="jlt-flex jlt-flex-col">
                  {posts[key].map((post) => (
                    <li key={post.id}>
                      <Link
                        href={`/glossary/${post.slug || "#"}`}
                        className="hover:jlt-text-[#256EFF] jlt-transition-all jlt-duration-150 jlt-py-1 jlt-block"
                      >
                        <span dangerouslySetInnerHTML={{ __html: post?.title?.rendered || "" }} />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              {Object.keys(posts)?.length > ++i ? <hr /> : null}
            </Fragment>
          ))}

        {posts["Others"]?.filter((post) =>
          post.title?.rendered?.toLowerCase()?.includes(searchText.toLowerCase())
        )?.length ? (
          <div className="jlt-flex jlt-gap-12">
            <div className="jlt-w-16 jlt-h-16 jlt-bg-gray-200 jlt-flex jlt-items-center jlt-justify-center jlt-text-base jlt-font-medium jlt-rounded-full">
              Others
            </div>
            <ul>
              {posts["Others"]
                ?.filter((post) =>
                  post.title?.rendered?.toLowerCase()?.includes(searchText.toLowerCase())
                )
                ?.map((post) => (
                  <li key={post.id}>
                    <Link
                      href={`/glossary/${post.slug || "#"}`}
                      className="hover:jlt-text-[#256EFF] jlt-transition-all jlt-duration-150 jlt-py-1 jlt-block"
                    >
                      <span dangerouslySetInnerHTML={{ __html: post?.title?.rendered || "" }} />
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default GlossaryList;
