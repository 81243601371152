// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import { sanitize } from "@litonarefin/utils/miscellaneous";
// import Image from "next/image";
import Link from "next/link";

const Support = ({ attributes }) => {
  // const allAOS = getAOSAnimation(attributes);

  return (
    <Link
      {...(attributes?.anchor && { id: attributes?.anchor })}
      // {...allAOS}
      href={attributes?.supportUrl?.url || "#"}
      target={attributes?.supportUrl?.blank ? "_blank" : ""}
      rel={attributes?.supportUrl?.rel ? "nofollow noopener" : "noopener"}
    >
      <div
        className={`jlt-p-8 jlt-shadow-lg jlt-dshadow-[0px 0px 35px rgba(173, 173, 188, 0.1)] jlt-bg-gradient-to-r jlt-from-[#FFFFFF] jlt-to-[rgba(255,255,255,1)_100%] ${
          attributes?.designStyle == "style2"
            ? "jlt-rounded-full jlt-flex jlt-items-center jlt-justify-center jlt-w-32 jlt-h-32 jlt-flex-col"
            : "jlt-rounded-lg jlt-w-full"
        }`}
      >
        <div className="jlt-rounded-2xl jlt-flex jlt-items-center jlt-justify-center sm:jlt-justify-start">
          {attributes?.supportImageURL && attributes?.designStyle == "style2" ? (
            <img
              src={attributes?.supportImageURL}
              alt={attributes?.supportImageAlt}
              width={32}
              height={32}
            />
          ) : (
            <img
              src={attributes?.supportImageURL}
              alt={attributes?.supportImageAlt}
              width={80}
              height={80}
            />
          )}
        </div>
        <h3
          className={`jlt-text-[#16213E] jlt-font-bold jlt-leading-7 jlt-text-center sm:jlt-text-left ${
            attributes?.designStyle == "style2" ? "jlt-text-sm jlt-mt-2" : "jlt-text-lg jlt-mt-4"
          }`}
          dangerouslySetInnerHTML={{
            __html: sanitize(attributes?.supportName || ""),
          }}
        />
      </div>
    </Link>
  );
};

export default Support;
