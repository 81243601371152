"use client";

const WooCommerceButton = () => {
  return "Change To Paddle Billing";
};

export default WooCommerceButton;

// import { Fragment, useState } from "react";
// import { getBorderCSS } from "@litonarefin/utils/getBorderCSS";
// import { getDimensionCSS } from "@litonarefin/utils/getDimensionCSS";
// import getMinifyCSS from "@litonarefin/utils/getMinifyCSS";
// import Modal from "../Modal";
// import Loader from "../Loader";
// import Link from "next/link";
// import { useGlobalCartContext } from "@litonarefin/context/CartContext";

// const WooCommerceButton = ({ attributes }) => {
//     const [isOpenModal, setIsModalOpen] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const [msg, setMsg] = useState("");

//     const { cart, setCart } = useGlobalCartContext();

//     const {
//         btnText,
//         productId,
//         productVariation,
//         btnColor,
//         btnBgColor,
//         border,
//         borderRadius,
//         padding,
//         width,
//     } = attributes;

//     // const allAOS = getAOSAnimation(attributes);

//     const handleAddToCart = async () => {
//         setIsModalOpen(true);
//         setIsLoading(true);

//         const isExits = cart?.find((d) => d.variationId === +productVariation);

//         try {
//             if (!isExits) {
//                 const res = await fetch(
//                     `${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/product?product_id=${productId}&variation_id=${productVariation}`,
//                     {
//                         cache: "no-cache",
//                     }
//                 );
//                 const { data: result } = await res.json();

//                 if (!result?.id) return;

//                 const newData = [
//                     ...(cart || []),
//                     {
//                         quantity: 1,
//                         id: result?.id,
//                         variationId: +productVariation,
//                         planId: result?.variations_data?.variations?.[productVariation]?.plan_id,
//                         price: result?.variations_data?.variations?.[productVariation]?.price,
//                         license: result?.variations_data?.variations?.[productVariation]?.license,
//                         expires: result?.variations_data?.variations?.[productVariation]?.expires,
//                         img: result?.variations_data?.image,
//                         title: result?.title?.rendered,
//                     },
//                 ];
//                 setCart(newData);
//                 setMsg({});
//             } else {
//                 setMsg({
//                     productName: isExits?.title,
//                 });
//             }
//         } catch (e) {
//             // console.log(e);
//         } finally {
//             setIsLoading(false);
//         }
//     };

//     return (
//         <Fragment>
//             <button
//                 {...(attributes?.anchor && { id: attributes?.anchor })}
//                 className="jlt-woocommearce-btn jlt-shadow-[0px_0px_20px_rgba(173,173,188,0.15)] jlt-rounded-[25px] jlt-py-3 jlt-px-6 jlt-font-bold jlt-text-sm jlt-leading-[18px] jlt-text-center jlt-text-white jlt-border-none jlt-cursor-pointer"
//                 // {...allAOS}
//                 onClick={handleAddToCart}>
//                 {btnText || "Button Text"}
//             </button>

//             {isOpenModal ? (
//                 <Modal onCloseModal={() => setIsModalOpen(false)} width="1024px" height="250px">
//                     {isLoading ? (
//                         <div
//                             style={{ width: "1024px", height: "250px" }}
//                             className="jlt-p-9 jlt-bg-white jlt-flex jlt-items-center jlt-justify-center">
//                             <Loader />
//                         </div>
//                     ) : (
//                         <CartProduct data={cart} msg={msg} />
//                     )}
//                 </Modal>
//             ) : null}

//             <style jsx>{`
//                 .jlt-woocommearce-btn {
//                     ${getMinifyCSS(`
//                         ${btnBgColor ? `background: ${btnBgColor};` : ""}
//                         ${btnColor ? `color: ${btnColor};` : ""}
//                         ${borderRadius ? `border-radius: ${borderRadius}px;` : ""}
//                         ${width ? `width: ${width};` : ""}
//                         ${getBorderCSS(border) ? getBorderCSS(border) : ""}
//                         ${
//                             getDimensionCSS(padding, "padding")
//                                 ? getDimensionCSS(padding, "padding")
//                                 : ""
//                         }
//                     `)}
//                 }
//             `}</style>
//         </Fragment>
//     );
// };

// export default WooCommerceButton;

// const CartProduct = ({ data, msg }) => {
//     return (
//         <Fragment>
//             <div className="jlt-p-4 sm:jlt-p-8 jlt-bg-[#FAFCFF] lg:jlt-w-[1024px] jlt-mx-4 lg:jlt-mx-0">
//                 {!!msg?.productName ? (
//                     <p className="jlt-text-red-500">
//                         <strong>{msg.productName}</strong> already added on your cart. Go to{" "}
//                         <Link href="/cart" className="jlt-font-bold jlt-text-[#0d49ce]">
//                             Cart Page
//                         </Link>{" "}
//                         for manage order quantity.
//                     </p>
//                 ) : (
//                     <h4 className="jlt-font-bold jlt-text-2xl jlt-leading-[1]">
//                         Successfully Added to Your Cart
//                     </h4>
//                 )}

//                 <div className="jlt-mt-6 jlt-flex jlt-justify-between jlt-flex-col sm:jlt-flex-row">
//                     <div className="jlt-flex jlt-flex-col jlt-gap-6">
//                         {data?.map((cartItem, index) => (
//                             <div
//                                 key={index}
//                                 className="jlt-flex jlt-items-center jlt-justify-between">
//                                 <div className="jlt-flex jlt-items-center jlt-gap-5">
//                                     {cartItem?.img ? (
//                                         <img
//                                             src={cartItem?.img}
//                                             alt={cartItem?.title}
//                                             width={48}
//                                             height={48}
//                                         />
//                                     ) : null}

//                                     <div>
//                                         <h3 className="jlt-font-bold jlt-text-lg jlt-text-[#1D2939]">
//                                             {cartItem?.title}
//                                         </h3>
//                                         <p className="jlt-text-xs jlt-text-[#667085]">
//                                             License:{" "}
//                                             <span className="jlt-font-bold">
//                                                 {cartItem?.license}
//                                             </span>
//                                             , Price:{" "}
//                                             <span className="jlt-font-bold">
//                                                 ${cartItem?.price}
//                                             </span>
//                                             , Expires:{" "}
//                                             <span className="jlt-font-bold">
//                                                 {cartItem?.expires}
//                                             </span>
//                                         </p>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </div>
//                     <div className="jlt-flex jlt-items-center sm:jlt-justify-center jlt-mt-4 sm:jlt-mt-0">
//                         <Link
//                             href="/checkout"
//                             className="jlt-py-2 jlt-px-6 jlt-rounded jlt-text-white jlt-font-bold"
//                             style={{
//                                 background: "#12B76A",
//                             }}>
//                             Checkout
//                         </Link>
//                     </div>
//                 </div>
//             </div>
//         </Fragment>
//     );
// };
