import Link from "next/link";

const DocsCard = ({ attributes }) => {
  const { title, description, btnText, btnLink, imageURL, imageAlt, docOne, docTwo, docThree } =
    attributes;

  // const allAOS = getAOSAnimation(attributes);

  return (
    <div
      {...(attributes?.anchor && { id: attributes?.anchor })}
      // {...allAOS}
      className="jlt-bg-[linear-gradient(124.09deg,#FFFFFF,90%,rgba(255,255,255,0)100%)] jlt-rounded-lg jlt-shadow-[0px_0px_45px_rgba(173,173,188,0.15)] jlt-border jlt-border-[#cfd2d6] jlt-h-[100%] jlt-p-6"
    >
      <div>
        <div className="jlt-flex jlt-items-center jlt-gap-4">
          {imageURL ? (
            <div className="jlt-w-[50px] jlt-h-[50px]">
              <img src={imageURL} alt={imageAlt} width={96} height={96} />
            </div>
          ) : null}
          <h2
            className="jlt-font-bold jlt-text-xl jlt-text-[#101828]"
            dangerouslySetInnerHTML={{ __html: title || "" }}
          />
        </div>
        <p
          className="jlt-text-base jlt-text-[#344054] jlt-mt-4"
          dangerouslySetInnerHTML={{ __html: description || "" }}
        />
        <div className="jlt-mt-4 jlt-flex jlt-flex-col jlt-gap-1">
          {docOne ? <Item item={docOne} /> : null}
          {docTwo ? <Item item={docTwo} /> : null}
          {docThree ? <Item item={docThree} /> : null}
        </div>
      </div>
      <Link
        href={`${btnLink?.url || "/"}`}
        className="jlt-w-max jlt-bg-[--primary] jlt-py-3 jlt-px-4 jlt-rounded-lg jlt-text-white jlt-text-sm jlt-font-bold jlt-mt-4 jlt-inline-block"
      >
        {btnText}
      </Link>
    </div>
  );
};

export default DocsCard;

const Item = ({ item }) => {
  return (
    <div className="jlt-flex jlt-items-center">
      <svg
        width="18"
        height="17"
        viewBox="0 0 18 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.4342 1.08813H1V15.6961H15.6079V10.8267"
          stroke="var(--primary)"
          strokeWidth="1.875"
          strokewinecap="round"
          strokewinejoin="round"
        />
        <path
          d="M5.1748 8.04466L8.65288 11.5227L17.0003 2.47974"
          stroke="var(--primary)"
          strokeWidth="1.875"
          strokewinecap="round"
          strokewinejoin="round"
        />
      </svg>
      <p
        className="jlt-ml-2 jlt-font-medium jlt-text-lg jlt-text-[#3f475a] hover:jlt-text-[--primary] jlt-transition-all jlt-duration-150"
        dangerouslySetInnerHTML={{ __html: item || "" }}
      />
    </div>
  );
};
