"use client";

import { sanitize } from "@litonarefin/utils/miscellaneous";
import getNumberUnit from "@litonarefin/utils/getNumberUnit";

const PluginInfo = ({ pluginInfo, attributes }) => {
  const convertDateAndTime = (time) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let fulldateArr = time.split(" ");
    let dateArr = fulldateArr?.[0].split("-");
    let year = dateArr?.[0];
    let month = dateArr?.[1];
    let day = dateArr?.[2];
    return `${months?.[month - 1]} ${day}, ${year}`;
  };

  return (
    <div {...(attributes?.anchor && { id: attributes?.anchor })}>
      <div className="jlt-container jlt-mx-auto jlt-rounded-lg">
        <div className="jlt-relative">
          {pluginInfo?.slug ? (
            <img
              className={"jlt-rounded-t-lg"}
              src={`https://ps.w.org/${pluginInfo.slug}/assets/banner-772x250.png`}
              alt={"Banner"}
              width={772}
              height={250}
              style={{ width: "100%" }}
            />
          ) : null}
          <div className="jlt-w-[666px] jlt-h-12 jlt-py-2 jlt-px-4 jlt-rounded-lg jlt-bg-[#000000] jlt-opacity-[0.7] jlt-absolute jlt-bottom-6 jlt-left-6">
            <h2 className="jlt-text-white jlt-text-xl jlt-leading-8">{pluginInfo?.name}</h2>
          </div>
        </div>
        <div className="jlt-bg-[#F2F4F7] jlt-flex jlt-justify-between jlt-rounded-b-lg">
          <div className="jlt-p-4 jlt-flex jlt-gap-6">
            {pluginInfo?.slug ? (
              <div>
                <img
                  className={"jlt-rounded-t-lg"}
                  src={`https://ps.w.org/${pluginInfo?.slug}/assets/icon.svg`}
                  alt={"Icon"}
                  width={88}
                  height={88}
                />
              </div>
            ) : null}

            <div>
              <div className="jlt-flex jlt-items-center jlt-gap-1">
                <p className="jlt-text-[#475467] jlt-font-normal jlt-text-sm">Author:</p>
                <div
                  className="jlt-text-[#1B5DE0] jlt-font-normal jlt-text-sm"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(pluginInfo?.author || ""),
                  }}
                />
              </div>
              <p className="jlt-text-[#475467] jlt-font-bold jlt-text-xs jlt-mt-[10px]">
                Current Version: <span className="jlt-font-normal">{pluginInfo?.version}</span>
              </p>
              <p className="jlt-text-[#475467] jlt-font-bold jlt-text-xs jlt-mt-1">
                Last Update:{" "}
                <span className="jlt-font-normal">
                  {pluginInfo?.last_updated ? convertDateAndTime(pluginInfo?.last_updated) : null}
                </span>
              </p>
              <div className="jlt-flex jlt-items-center jlt-gap-1">
                <p className="jlt-text-[#475467] jlt-font-bold jlt-text-xs jlt-mt-1">Download:</p>
                <button
                  onClick={() => (window.location.href = pluginInfo?.download_link)}
                  className="jlt-mt-1 jlt-font-normal jlt-text-xs jlt-text-[#1B5DE0]"
                >
                  {`${pluginInfo?.slug}.${pluginInfo.version}.zip`}
                </button>
              </div>
            </div>
          </div>
          <div className="jlt-pt-[15px] jlt-pb-[17px] jlt-pr-4 jlt-flex jlt-gap-[1px]">
            <div className="jlt-w-[98px] jlt-h-[88px] jlt-bg-[#6172F3] jlt-flex jlt-justify-center jlt-items-center jlt-flex-col">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0439 4.60458C15.3512 3.982 15.5048 3.67071 15.7134 3.57125C15.8949 3.48471 16.1058 3.48471 16.2873 3.57125C16.4959 3.67071 16.6495 3.982 16.9568 4.60458L19.8724 10.5112C19.9631 10.695 20.0085 10.7869 20.0748 10.8583C20.1335 10.9214 20.2039 10.9726 20.2821 11.009C20.3704 11.0501 20.4718 11.0649 20.6746 11.0945L27.1963 12.0478C27.8831 12.1482 28.2265 12.1983 28.3854 12.3661C28.5236 12.512 28.5887 12.7126 28.5623 12.9119C28.5321 13.1409 28.2835 13.3831 27.7863 13.8673L23.0689 18.4621C22.9219 18.6053 22.8483 18.6769 22.8009 18.7621C22.7589 18.8376 22.7319 18.9204 22.7215 19.0062C22.7098 19.103 22.7272 19.2042 22.7619 19.4065L23.8749 25.8963C23.9923 26.5808 24.051 26.9231 23.9407 27.1262C23.8447 27.3029 23.6741 27.4268 23.4764 27.4635C23.2492 27.5056 22.9418 27.344 22.3271 27.0207L16.4968 23.9546C16.3152 23.8591 16.2243 23.8113 16.1286 23.7925C16.0439 23.7759 15.9568 23.7759 15.872 23.7925C15.7764 23.8113 15.6855 23.8591 15.5039 23.9546L9.67356 27.0207C9.05888 27.344 8.75154 27.5056 8.52429 27.4635C8.32657 27.4268 8.15596 27.3029 8.05998 27.1262C7.94966 26.9231 8.00836 26.5808 8.12576 25.8963L9.23884 19.4065C9.27354 19.2042 9.29089 19.103 9.27915 19.0062C9.26876 18.9204 9.24181 18.8376 9.1998 18.7621C9.15236 18.6769 9.07883 18.6053 8.93177 18.4621L4.2144 13.8673C3.71721 13.3831 3.46861 13.1409 3.43836 12.9119C3.41204 12.7126 3.47706 12.512 3.61533 12.3661C3.77424 12.1983 4.11762 12.1482 4.80438 12.0478L11.3261 11.0945C11.5289 11.0649 11.6303 11.0501 11.7186 11.009C11.7968 10.9726 11.8672 10.9214 11.9259 10.8583C11.9922 10.7869 12.0376 10.695 12.1283 10.5112L15.0439 4.60458Z"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <h2 className="jlt-text-white jlt-text-sm">{pluginInfo?.rating}%</h2>
              <h3 className="jlt-text-white jlt-text-xs jlt-font-normal">Ratings</h3>
            </div>
            <div className="jlt-w-[98px] jlt-h-[88px] jlt-bg-[#6172F3] jlt-flex jlt-justify-center jlt-items-center jlt-flex-col">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.33366 21.6563C3.72568 20.58 2.66699 18.7469 2.66699 16.6667C2.66699 13.5419 5.05567 10.9751 8.10665 10.6925C8.73075 6.89618 12.0273 4 16.0003 4C19.9733 4 23.2699 6.89618 23.894 10.6925C26.945 10.9751 29.3337 13.5419 29.3337 16.6667C29.3337 18.7469 28.275 20.58 26.667 21.6563M10.667 22.6667L16.0003 28M16.0003 28L21.3337 22.6667M16.0003 28V16"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <h2 className="jlt-text-white jlt-text-sm">
                {getNumberUnit(pluginInfo?.active_installs) == "0"
                  ? getNumberUnit(pluginInfo?.active_installs || 0)
                  : `${getNumberUnit(pluginInfo?.active_installs)}+`}
              </h2>
              <h3 className="jlt-text-white jlt-text-xs jlt-font-normal">Installs</h3>
            </div>
            <div className="jlt-w-[98px] jlt-h-[88px] jlt-bg-[#6172F3] jlt-flex jlt-justify-center jlt-items-center jlt-flex-col">
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3337 29.3332L13.3337 25.3332M13.3337 25.3332L17.3337 21.3332M13.3337 25.3332H20.0003C25.155 25.3332 29.3337 21.1545 29.3337 15.9998C29.3337 12.2766 27.1536 9.06263 24.0003 7.56468M8.00033 24.435C4.84706 22.937 2.66699 19.723 2.66699 15.9998C2.66699 10.8452 6.84567 6.6665 12.0003 6.6665H18.667M18.667 6.6665L14.667 2.6665M18.667 6.6665L14.667 10.6665"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>

              <h2 className="jlt-text-white jlt-text-sm">WP {pluginInfo?.requires}+</h2>
              <h3 className="jlt-text-white jlt-text-xs jlt-font-normal">Requires</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PluginInfo;
