// import { getAOSAnimation } from "@litonarefin/utils/getAOSAnimation";
import { sanitize } from "@litonarefin/utils/miscellaneous";
import Link from "next/link";

const PriceCard = ({ attributes }) => {
  const {
    title,
    description,
    price,
    priceDuration,
    btnText,
    btnLink,
    imageURL,
    imageAlt,
    isOffter,
    offerText,
  } = attributes;

  // const allAOS = getAOSAnimation(attributes);

  return (
    <div
      {...(attributes?.anchor && { id: attributes?.anchor })}
      // {...allAOS}
      className="jlt-bg-[linear-gradient(124.09deg,#FFFFFF,90%,rgba(255,255,255,0)100%)] jlt-p-12 jlt-rounded-lg jlt-shadow-[0px_0px_45px_rgba(173,173,188,0.15)] jlt-relative jlt-overflow-hidden"
    >
      {isOffter ? (
        <div className="jlt-absolute jlt-top-0 jlt-right-0 jlt-bg-[#f1577c] jlt-py-[5px] jlt-px-[10px] jlt-flex jlt-items-center jlt-justify-center">
          <span
            className="jlt-text-sm jlt-font-semibold jlt-text-white"
            dangerouslySetInnerHTML={{
              __html: sanitize(offerText || ""),
            }}
          />
        </div>
      ) : null}

      <div className="jlt-flex jlt-items-center jlt-gap-5 jlt-mb-8">
        {imageURL ? (
          <div className="jlt-w-16 jlt-h-16 jlt-flex jlt-items-center jlt-justify-center">
            <img src={imageURL} alt={imageAlt} width={64} height={64} />
          </div>
        ) : null}
        <h2
          className="jlt-text-[24px] jlt-font-bold jlt-text-[#101828]"
          dangerouslySetInnerHTML={{ __html: sanitize(title || "") }}
        />
      </div>

      <p
        className="jlt-text-lg jlt-text-[#344054]"
        dangerouslySetInnerHTML={{ __html: sanitize(description || "") }}
      />

      <div className="jlt-mt-10 jlt-flex jlt-items-center">
        <h3
          className="jlt-text-[#EB5E81] jlt-text-4xl jlt-font-bold"
          dangerouslySetInnerHTML={{ __html: sanitize(price || "") }}
        />

        <p
          className="jlt-text-[#344054] jlt-ml-3"
          dangerouslySetInnerHTML={{
            __html: sanitize(priceDuration || ""),
          }}
        />
      </div>

      <Link
        href={`${btnLink?.url || "/"}`}
        target={btnLink?.blank ? "_blank" : ""}
        rel={btnLink?.rel ? "nofollow noopener" : ""}
        className="jlt-bg-[--primary] jlt-py-4 jlt-px-6 jlt-rounded-full jlt-text-white jlt-text-sm jlt-font-bold jlt-mt-6 jlt-inline-block"
      >
        {btnText}
      </Link>
    </div>
  );
};

export default PriceCard;
