"use client";

import axios from "axios";
import Button from "./Button";
import FormRenderer from "./FormRenderer";
import { handleToastr } from "@litonarefin/utils/handleToastr";

const Form = ({ formData, formId }) => {
  const fieldsData = JSON.parse(formData);

  async function onSubmit(event) {
    event.preventDefault();

    const formData = new FormData(event.target);

    const obj = {};
    formData.forEach((val, key) => {
      Object.assign(obj, { [key]: val });
    });

    const { data } = await axios.post(`/api/fluent-form?form_id=${formId}`, obj);

    if (data?.success && data?.data?.insert_id) {
      handleToastr("Thank you for your message. We will get in touch with you shortly", "success");

      // Reset the form after successful submission
      event.target.reset();
    } else {
      handleToastr(data?.message, "error");
    }
  }

  return (
    <form onSubmit={onSubmit}>
      <FormRenderer fields={fieldsData?.fields} />
      {fieldsData?.submitButton && !JSON.stringify(fieldsData).includes("custom_submit_button") ? (
        <Button data={fieldsData.submitButton} />
      ) : null}
    </form>
  );
};

export default Form;
