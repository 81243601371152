"use client";

import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import isEmpty from "lodash/isEmpty";
import { getIcon } from "@litonarefin/utils/icons";
import { getStyledIcon } from "@litonarefin/utils/getStyledIcon";
import { addQueryArgs } from "@litonarefin/utils/index";

export const DocsSearch = ({ miniIcon = false }) => {
  const [searchText, setSearchText] = useState("");
  const [categorySlug, setCategorySlug] = useState("");
  const [parentCategories, setParentCategories] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoading] = useState(false);

  let dropRef = useRef();

  const getSingleDocSearch = async (cateSlug, searchQuery) => {
    const args = addQueryArgs({ search: searchQuery, category_slug: cateSlug });

    const res = await fetch(`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/docs/search${args}`);
    if (!res.ok) return [];

    const data = await res.json();

    setSearchData(data || []);
    setLoading(false);
    setIsSearch(false);
  };

  const getAllDocsName = async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/docs/parent-docs`);
    if (!res.ok) return [];

    const categories = await res.json();

    setParentCategories(categories);
  };

  const getAllDocsSearch = async (searchQuery) => {
    const args = addQueryArgs({ search: searchQuery });

    const res = await fetch(`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/api/docs/search${args}`);
    if (!res.ok) return [];

    const data = await res.json();

    setSearchData(data || []);
    setLoading(false);
    setIsSearch(false);
  };

  useEffect(() => {
    getAllDocsName();
  }, []);

  useEffect(() => {
    if (!categorySlug) return;

    let interval;

    document.querySelector("#txtSearch").addEventListener("keypress", function (e) {
      if (e.key === "Enter") {
        if (!!searchText) {
          setOpen(true);
          setLoading(true);
          interval = setTimeout(() => {
            getAllDocsSearch(searchText);
          }, 300);
        } else {
          setOpen(false);
        }
      }
    });

    if (!!isSearch) {
      if (!!searchText) {
        setOpen(true);
        setLoading(true);
        interval = setTimeout(() => {
          getSingleDocSearch(categorySlug, searchText);
        }, 300);
      } else {
        setOpen(false);
      }
    }

    return () => clearTimeout(interval);
  }, [categorySlug, searchText, isSearch]);

  useEffect(() => {
    if (!!categorySlug) return;
    let interval;

    document.querySelector("#txtSearch").addEventListener("keypress", function (e) {
      if (e.key === "Enter") {
        if (!!searchText) {
          setOpen(true);
          setLoading(true);
          interval = setTimeout(() => {
            getAllDocsSearch(searchText);
          }, 300);
        } else {
          setOpen(false);
        }
      }
    });

    if (!!isSearch) {
      if (!!searchText) {
        setOpen(true);
        setLoading(true);
        interval = setTimeout(() => {
          getAllDocsSearch(searchText);
        }, 300);
      } else {
        setOpen(false);
      }
    }

    return () => clearTimeout(interval);
  }, [searchText, categorySlug, isSearch]);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (dropRef.current && !dropRef.current.contains(e.target)) {
        setOpen(false);
      }
    });
  }, []);

  return (
    <div
      ref={dropRef}
      className="jlt-docs-search jlt-bg-[#FFFFFF] jlt-border-[1px] jlt-border-[#EBEEF5] jlt-rounded-[44px] jlt-shadow-[0px_0px_10px_rgba(173,173,188,0.1)] jlt-pl-2 jlt-pr-1 jlt-flex jlt-justify-between jlt-items-center jlt-gap-2 jlt-relative !jlt-max-w-[400px] sm:!jlt-max-w-[650px]"
    >
      <div className="jlt-h-full jlt-w-full jlt-flex jlt-gap-2">
        <select
          defaultValue={"allDocs"}
          className="jlt-w-[120px] jlt-px-2 jlt-py-3 jlt-text-sm jlt-text-[#475467] jlt-font-normal jlt-outline-none jlt-bg-[#ffff] jlt-rounded-tl-2xl jlt-rounded-bl-2xl"
          onChange={(e) => setCategorySlug(e.target.value)}
        >
          <option value={""}>All Docs</option>
          {parentCategories?.map((pCategory) => (
            <option key={pCategory?.id} value={pCategory?.slug}>
              {pCategory?.name}
            </option>
          ))}
        </select>
        <input
          id="txtSearch"
          onChange={(e) => setSearchText(e.target.value)}
          type="text"
          placeholder="Documentation Search..."
          className="jlt-h-[44px] jlt-w-full jlt-text-sm jlt-text-[#72798A] jlt-font-normal jlt-outline-none"
        />
      </div>
      <button
        onClick={() => {
          searchText ? setOpen(true) : null;
          setIsSearch(true);
        }}
        className="jlt-py-2 jlt-px-3 jlt-bg-[--primary] jlt-rounded-[33px] jlt-text-sm jlt-text-[#FFFFFF] jlt-font-semibold jlt-flex jlt-items-center jlt-justify-center jlt-gap-2"
      >
        {!miniIcon ? <span className="jlt-hidden sm:jlt-block">Search</span> : null}
        {getStyledIcon(getIcon("search"), "white", "stroke")}
      </button>

      {open ? (
        <div className="jlt-w-[100%] jlt-min-h-[50px] jlt-bg-white jlt-p-4 jlt-absolute jlt-top-full jlt-left-0 jlt-z-10 jlt-shadow-[0px_18px_28px_rgba(173,173,188,0.15)] jlt-rounded-lg jlt-border jlt-border-[#EBEEF5] jlt-mt-2">
          {isEmpty(searchData) && !loading ? (
            <div className="jlt-text-center">Nothing found! Try with another keyword!</div>
          ) : loading ? (
            <div className="jlt-text-center">Loading...</div>
          ) : (
            <ul>
              {searchData?.map((data, index) => {
                const category = data?.categories?.[0]?.slug;
                const parentCategory = data?.categories?.[0]?.parent?.slug;

                return (
                  <li key={index} className="jlt-px-4 jlt-py-1">
                    {parentCategory ? (
                      <Link
                        href={`/docs/${parentCategory}/${category}/${data?.slug}`}
                        onClick={() => {
                          setOpen(false);
                        }}
                        className="jlt-text-[--primary] hover:jlt-text-[--primary]"
                      >
                        <span dangerouslySetInnerHTML={{ __html: data?.title || "" }} />
                      </Link>
                    ) : (
                      <Link
                        href={`/docs/${category}/${data?.slug}`}
                        onClick={() => {
                          setOpen(false);
                        }}
                        className="jlt-text-[--primary] hover:jlt-text-[--primary]"
                      >
                        <span dangerouslySetInnerHTML={{ __html: data?.title || "" }} />
                      </Link>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ) : null}
    </div>
  );
};
