"use client";

import { sanitize } from "@litonarefin/utils/miscellaneous";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState, Fragment, useEffect } from "react";
import { getIcon } from "@litonarefin/utils/icons";

const Code = ({ attributes }) => {
  const [copiedText, setCopiedText] = useState({ copied: false });

  useEffect(() => {
    let interval;
    if (copiedText?.copied === true) {
      interval = setInterval(() => {
        setCopiedText({ copied: false });
      }, 6000);
    }
    return () => clearInterval(interval);
  }, [copiedText]);

  return (
    <Fragment>
      <div
        {...(attributes?.anchor && { id: attributes?.anchor })}
        className="jlt-relative jlt-p-4 jlt-border jlt-border-[#D0D5DD] jlt-rounded-lg"
      >
        {attributes?.className ? (
          <code
            className={`${attributes?.className} jlt-break-all sm:jlt-break-normal`}
            dangerouslySetInnerHTML={{
              __html: sanitize(attributes?.page_content || ""),
            }}
          />
        ) : (
          <code
            className="jlt-break-all sm:jlt-break-normal"
            dangerouslySetInnerHTML={{
              __html: sanitize(attributes?.page_content || ""),
            }}
          />
        )}

        <CopyToClipboard
          text={`${attributes?.page_content?.replace(/<[^>]*>/g, "")}`}
          onCopy={() => setCopiedText({ copied: true })}
        >
          <button
            className={`jlt-absolute jlt-top-[15px] jlt-right-4 jlt-border jlt-border-[#D0D5DD] jlt-py-2 jlt-px-4 jlt-rounded-[4px] jlt-text-xs jlt-flex jlt-items-center jlt-gap-2 jlt-bg-white`}
          >
            {copiedText?.copied ? (
              <Fragment>{getIcon("check")}</Fragment>
            ) : (
              <Fragment>{getIcon("copy")}</Fragment>
            )}
          </button>
        </CopyToClipboard>
      </div>
    </Fragment>
  );
};

export default Code;
