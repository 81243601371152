import { clsx } from "clsx";
import { twMerge } from "tailwind-merge";

import { getSourceSite } from "./getSourceSite";
const sourceSite = getSourceSite();

// export const REVALIDATE = 3600; // 1 hour
export const REVALIDATE = process.env.NEXT_PUBLIC_STAGING ? 60 : 900; // 15 minute
// export const REVALIDATE = 5; // 5 second
// export const REVALIDATE = 30; // 30 second

// Blog Slug Prefix
export const BLOG_PATH_PREFIX = sourceSite === "wpadminify" ? "" : "/blog";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

/**
 * Query Build
 */
export function addQueryArgs(obj = {}) {
  if (!Object.keys(obj).length) return "";
  return `?${buildQueryString(obj)}`;
}

export function buildQueryString(data) {
  let string = "";

  const stack = Object.entries(data);

  let pair;
  while ((pair = stack.shift())) {
    let [key, value] = pair;

    // Support building deeply nested data, from array or object values.
    const hasNestedData = Array.isArray(value) || (value && value.constructor === Object);

    if (hasNestedData) {
      // Push array or object values onto the stack as composed of their
      // original key and nested index or key, retaining order by a
      // combination of Array#reverse and Array#unshift onto the stack.
      const valuePairs = Object.entries(value).reverse();
      for (const [member, memberValue] of valuePairs) {
        stack.unshift([`${key}[${member}]`, memberValue]);
      }
    } else if (value !== undefined) {
      // Null is treated as special case, equivalent to empty string.
      if (value === null) {
        value = "";
      }

      string += "&" + [key, value].map(encodeURIComponent).join("=");
    }
  }

  // Loop will concatenate with leading `&`, but it's only expected for all
  // but the first query parameter. This strips the leading `&`, while still
  // accounting for the case that the string may in-fact be empty.
  return string.substr(1);
}

export function getExpire(num) {
  if (!num) return "";

  let plan_expire_text = "";

  switch (Number(num)) {
    case 0:
      plan_expire_text = "Lifetime";
      break;
    case 1:
      plan_expire_text = "Daily";
      break;
    case 7:
      plan_expire_text = "Weekly";
      break;
    case 14:
      plan_expire_text = "14 Days";
      break;
    case 30:
      plan_expire_text = "Monthly";
      break;
    case 90:
      plan_expire_text = "3 Months";
      break;
    case 180:
      plan_expire_text = "6 Months";
      break;
    case 365:
      plan_expire_text = "Yearly";
      break;
    case 730:
      plan_expire_text = "2 Years";
      break;
    case 1095:
      plan_expire_text = "3 Years";
      break;
    case 1460:
      plan_expire_text = "4 Years";
      break;
    case 1825:
      plan_expire_text = "5 Years";
      break;
  }

  return plan_expire_text;
}

/**
 * Get Robots Object
 * @param {boolean} follow
 * @returns [Object] {index: boolean, follow: boolean}
 */
export function getRobots(follow = true) {
  if (process.env.NEXT_PUBLIC_STAGING || follow === false) {
    return {
      index: false,
      follow: false,
    };
  } else {
    return {
      index: true,
      follow: true,
    };
  }
}

/**
 * Generate Random Passowrd
 * @param {*} length
 * @returns
 */
export function generateRandomPassword(length = 8) {
  const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890";
  let pass = "";
  const alphaLength = alphabet.length;

  for (let i = 0; i < length; i++) {
    const n = Math.floor(Math.random() * alphaLength);
    pass += alphabet[n];
  }

  return pass;
}
